.navbar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 2000;
  overflow: hidden;
  /* border: solid 1px black; */
}

.active{
    height: 4rem;
    width: 100%;
    position: fixed;
    top: 0px;
    transition: 0.3s linear;
    display: flex;
    justify-content:stretch;
    align-items: center;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    z-index: 40;
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    /* padding: 0 7%; */
}
.hidden{
    height: 4rem;
    width: 100%;
    z-index: 40;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
    position: fixed;
    top: -80px;
    transition: 0.3s linear;

}

.nav-act{
  box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.1);
}

.nav-text-white{
color: white;
}

.nav-item{
  padding-left: 15px;
  margin-right: 15px;
  font-weight: 600;
}

.nav-btn{
  height: 50px;
  width: 170px;
  background-color:var(--dark-blue-color);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: var(--combination-color);
  border: none;
  outline: none;
  cursor: pointer;
}

.nav-btn li {
  list-style: none;
}

.navbar-toggler-icon{
  color:var(--combination-color);
  font-size: 20px;
}

/* /////////////////////// */

.glasseffect{
  /* From https://css.glass */
/* background: rgba(255, 255, 255, 0.48); */
background: rgba(255, 255, 255, 0.147);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.014);
backdrop-filter: blur(6.7px);
-webkit-backdrop-filter: blur(6.7px);
/* border: 1px solid rgba(255, 255, 255, 0.3); */
}

/* //// */

.nav-link{
  color: var(--combination-color);
  font-weight: 1000;
}