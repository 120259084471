.projectslider_card {
    width: 100%;
    height: 350px;
    background-size: cover;
    position: relative;
    transition: all ease-out 0.30s;
    /* background-color: white; */
}


.projectslider_overlay {
    height: 100%;
    width: 100%;
    background-color: blue;
    opacity: 0;
    /* top: 120%; */
    position: absolute;
    z-index: 20;
    transition: all ease-out 0.30s;

}

.projectslider_heading {
    /* position: absolute; */
    color: white;
    z-index: 25;
    transition: all ease-out 0.30s;
    font-size: 35px;
}

.projectslider_typo {
    /* position: absolute; */
    color: white;
    z-index: 25;
    transition: all ease-out 0.30s;
    width: 100%;
    text-align: center;
}

.projectslider_card:hover .projectslider_overlay {
    opacity: 0.3;
}

.projectslider_content {
    border: solid 1px white;
    z-index: 20;
    position: absolute;
    /* top: 120%; */
    transition: all linear 0.40s;
    width: 75%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;    
    /* left: 15%; */
    flex-direction: column;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

}

.project-slider-btn-main button {
     border: solid 1px rgb(0,31,63);;
     border-radius: 50px;
     background-color: transparent;
     color: rgb(0,31,63);
     /* padding: 20px; */
     height: 50px;
     width: 120px;
     transition: all linear 0.30s;
}

.project-slider-btn-main button:hover {
   background-color: rgb(0,31,63);;
   color: var(--combination-color);
}

.projectslider_card:hover .projectslider_content {
    top: 0%;
}

.hr{
    width: 100px;
    background-color: var(--combination-color);
    height: 2px;
}

.projectslider-main p{
    text-align: center;
}

@media screen and (min-width:0px) and (max-width:767px) {
    .projectslider-main{
        margin-top: 100px;
    }
}