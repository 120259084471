body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244,245,247);
  font-family: 'Varela Round', sans-serif;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.circular-cursor {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  transform: translate(-50%, -50%);
}


/* <style> */
  @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
/* </style> */

p{
  color:rgb(105,105,105);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
