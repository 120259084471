.tottal-container{
    /* width: 100%; */
    height: 190px;
    /* border: solid 1px black; */
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.tottal-box{
    width: 200px;
    height: 100%;
    /* border: solid 1px black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.tottal-container-main{
    min-width: 100%;
    /* border: solid 1px red; */
    padding: 29px;
    background-color: rgb(239, 239, 239);
    margin-top: 100px;
    margin-bottom: 100px;
}

.tottal-round{
    height: 100px;
    width: 100px;
    background-color: var(--combination-color);
    border: solid 2px var(--dark-blue-color);
    color: var(--dark-blue-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    
}
.tottal-content{
    height: 70px;
    width: 100%;
    /* border: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.tottal-content h1{
    transform: translate(0px , 10px);
    font-weight: 1000;
}

@media screen and (min-width: 0px) and (max-width:750px) {
   .tottal-container{
    height: auto;
    flex-direction: column;
   }
   .tottal-box{
    margin-top: 20px;
    margin-bottom: 20px;
   }
}
