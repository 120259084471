.contactsendemail_container{
    width: 100%;
    height: max-content;
    background-color: rgb(245,245,245);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20vh;

    margin-bottom: 20vh;
}

.contactsendemail_inputs_main{
    width: 100%;
    height: 20px;
    /* border: solid 1px black; */
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.contactsendemail_container_ineer{
    width: 60%;
  
}

.contactsendemail_inputs_main input{
    width: 48%;
    border: none;
    outline: none;
    height: 42px;
    padding-left: 15px;
}

.contactsendemail_textarea_main{
    width: 100%;
    /* border: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 29px;
}

.contactsendemail_textarea_main textarea {

    width: 100%;
    padding-left: 15px;
    outline: none;
    border: none;
    padding-top: 15px;
    /* margin-top: 20px; */

}

.contactsendemail_btn_main{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

}

.contactsendemail_btn_main button {

    width: 100px;
    height: 35px;
    transition: all linear 0.30s;
    border: solid 1px blue;
    color: blue;
    background-color: white;

}

.contactsendemail_btn_main button:hover {
    background-color: blue;
    color: white;
    border: none;
}

