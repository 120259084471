.about-img {
    height: 550px;
    width: 100%;
    /* background-image: url('../../../utiles/images/buildermen.png'); */
    /* background-position: center; */
    background-size: cover;
    /* border: solid 1px black; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}

.about-img-1{

    height: 520px;
    width: 350px;
    border-radius: 20px;

}

.about-img-2{

}

.about-line-1{
    position: absolute;
    height: 90px;
    width: 60%;
    background-color: white;
    border-radius: 20px;
    left: -10%;
    top: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
}

.about-line-1 h1 {
    font-size: 15px;
    margin-left: 15px;

}

.about-line-2 h1 {
    font-size: 15px;
    margin-left: 15px;
}

.about-line-2{
    position: absolute;
    height: 90px;
    width: 60%;
    background-color: white;
    border-radius: 20px;
    left: -15%;
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
}

.about-img-2{

    height: 470px;
    width: 240px;
    border-radius: 20px;

}

.about-content {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.about-content h1 {
    color:var(--dark-blue-color);
    /* color: rgb(35, 35, 35); */
}

.about-content p {
    text-align: justify;
    color: rgb(105, 105, 105);
    width: 80%;
}

.about-content-2 p {
    text-align: justify;
    color: rgb(105, 105, 105);
}

.about-line-box{
    height: 70%;
    width: 60px;
    background-color:var(--dark-blue-color);
    border-radius: 10px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--combination-color);
    font-size: 40px;
}

.contaiener-fluid-gray{
    background-color: rgb(239,239,239);
    box-shadow: 0px 0px 5px 0px rgba(204, 204, 204, 0.75);
    /* background-attachment: fixed; */
}


@media screen and (min-width: 280px) and (max-width: 590px) {
    .about-img-2{
         display: none;
    }
}

@media screen and (min-width: 280px) and (max-width: 767px) {
    .about-line-1{
        width: 80%;
        transform: translate(20px);
    }
    .about-line-2{
        width: 80%;
    }
}


@media screen and (min-width: 280px) and (max-width: 992px) {
    .about-content-2 p {
        width: 100%;
        margin-top: 70px;
        /* border: solid 1px black; */
    }

    .about-content p {
        width: 100%;
        /* border: solid 1px black; */
    }

    .about-line-1{
        margin-left: 70px;
        padding-right: 5px;
    }
    
    .about-line-2{
        margin-left: 80px;
        padding-right: 5px;
    }

}
