.insta-in-footer{
    display: flex;
    /* flex-wrap: wrap; */
}

.footer-area{
    /* filter: brightness(30%); */
}

.img-fluid-fotter{
    width: 50px;
    padding: 10px;
    transform: scale(2);
    /* border: solid 1px burlywood; */
    margin-left: 20px;
    /* margin-bottom: 20px; */
    /* border-radius: 10px; */
}

.insta-in-footer-main{
    width: 100%;
    display: flex;
    flex-wrap:nowrap;
    /* border: solid 1px yellow; */
    margin-top: -20px;
    /* border: solid 1px greenyellow; */
}

.footer-wrapper{
    background-position: center;
    background-size: cover;
    /* filter: brightness(30%); */
    /* background-color: blue; */
}

.footer-tittle{
    /* border: solid 1px white; */
    padding-top: 15px;
    color: rgb(232, 232, 232);
    /* margin-bottom: 40px; */
}

.footer-tittle li{
    list-style: none;
    margin-left: -25px;
    margin-top: 10px;
}

.footer-logo{
    color: white;
    font-size: 40px;
    font-family: 1000;
}

.footer-copy-right{
    color: white;
}

.insta-in-footer-main{
    /* border: solid 1px red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    width: 300px;
    min-height: 300px;
}

.insta-in-footer{
    /* border: solid 1px orange; */
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-wrap: wrap;
}

.img-fluid-fotter{
    height: 50px;
    width: 50px;
    list-style: none;
    margin-top: 10px;
    /* margin-left: 10px; */
}