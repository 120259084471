.contactmap_content_main{
    /* height: 100%; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    
    /* border: solid 1px black; */
}

.contactmap_bold{
    font-weight: 800;
    color: rgb(96,96,96);
    margin-right: 5px;
}

.contactmap_link{
    color: rgb(27,189,232);
}
