.services_container {
    /* height: max-content; */
    min-width: 100%;
    background-image: url('../../../utiles/images/servicesbg.png');
    background-position: 2100px 000px;
    background-size: cover;
    background-attachment: fixed;
   /* filter: brightness(20%); */

    /* border: solid 1px black; */
}

.card {
    margin-top: 60px;
    transition: all ease-out 0.30s;
    border: none;
    border-radius: 20px;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    /* border: solid 1px black; */
    background-color: var(--dark-blue-color);
    /* width: 100%; */
}

.card-body {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    /* border: solid 1px black; */
}

.card-body h2 {
    transition: all linear 0.30s;
    position: absolute;
    top: 10%;
    /* transform: rotate(40deg); */
    /* transform: skewX(360deg); */
    color: var(--combination-color);
    transition: transform 0.60s ease;
    transform-style: preserve-3d;
    perspective: 1000px;
}

.card-body:hover.card-body h2 {
    /* transform: skewX(0deg); */
}

.card-body h4 {
    text-align: center;
    /* border: solid 1px green; */
    margin-top: 60px;
    color: var(--combination-color);
}

.card-body p {
    text-align: center;
    color: white;
}

.services-btn button {
    border: solid 1px var(--dark-blue-color);
    border-radius: 10px;
    color: var(--combination-color);
    background-color: var(--dark-blue-color);
    transition: all ease-out 0.30s;
    height: 40px;
    width: 150px;
    margin-top: 100px;
    margin-bottom: 60px;
}
/* 
.services-btn button:hover {
    background-color: blue;
    color: white;
} */

/* ///////////////////////////////////// */



.card::before,
.card::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    /* border: 1px solid rgb(234, 234, 234); */
    transition: width 0.30s ease;
    pointer-events: none;
    border-radius: 20px;
}

.card::before {
    top: 0;
    left: 0;
    border-width: 0 0 3px 0;
}

.card::after {
    bottom: 0;
    right: 0;
    border-width: 3px 0 0 0;
}

.card:hover::before,
.card:hover::after {
    width: 0;
    /* border-radius: 0px;  */
}

.card:hover::before {
    /* border-top-right-radius: 50px; */
}

.card:hover {
    transform: scale(1.1);

}


/* //////////////////////////////////// */

.card-body:hover.card-body h2 {
    transform: rotateY(180deg);
    /* Rotate the div 180 degrees along the X-axis on hover */
}

/* /////////////////// */

@media screen and (min-width: 700px) and (max-width: 992px) {
    .services_container {
        background-position: 3000px 0px;
    }
}
