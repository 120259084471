.carousel-container {
  height: 100vh;
  /* Set height to 100vh for full viewport height */
  width: 100%;
  /* Set width to 100% for full viewport width */
  display: flex;
  justify-content: flex-start;
  /* Horizontally left-aligned content */
  align-items: center;
  /* Vertically centered content */
  /* border: solid 2px black; */
  /* background-attachment: fixed; */
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  /* Make each slide 100% of the container's height */
  text-align: left;
  padding: 20px;
  background-color: #f0f0f0;
  /* Add your desired background color */
}

.carousel-container {
  background-image: url('https://images.unsplash.com/photo-1695605302505-32372d93493f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=500&q=60');
  /* Replace 'your-image.jpg' with the path to your background image */
  background-size: cover;
  background-position: center;
  border: 2px solid #333;
  /* Add a border with a color of your choice */
  border-radius: 8px;
  /* Optional: Add border radius for rounded corners */
  padding: 20px;
  /* Optional: Add padding to the container */
  overflow: hidden;
  /* Optional: Hide any content that goes beyond the container */
}

/* Style for the Carousel content */
.ant-carousel-slide {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 20px;
}

.carausel_background {
  height: 100vh;
  width: 100%;
  /* border: solid 2px greenyellow; */
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
}


.carausel_container {
  width: 60%;
  /* border: solid 1px black; */
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.carausel_container button {
  height: 50px;
  width: 200px;
  background-color: rgb(0, 31, 63);
  ;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* //////////////////// */

.header {
  position: relative;
  /* border: solid 2px goldenrod; */
  min-height: 100vh;
  background-attachment: fixed;
}

.slider-item {
  position: relative;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  overflow: hidden;
  /* border: solid 2px red; */

}

.slider-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-color: black; */
  opacity: 0.9;
  background: linear-gradient(to right, var(--dark-blue-color), transparent);
}

/* rgb(0,31,63); */

.caption {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-60%, -50%);
  /* border: solid 5px red; */
  width: 55vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* text-align: center; */
}

.Story_All_Tags {
  /* border: solid 2px greenyellow; */
  text-align: left;
  margin-top: 25px;
  color: white;
  font-size: 1.2rem;
  transition: opacity 0.5s ease;
}

.Story_All_Tags p {
  color: white;
}



.demo-heading {
  color: white;
  font-size: 3rem;
  /* border: solid 2px greenyellow; */
}

.main-heading {
  color: white;
  font-size: 2.7rem;
  transition: opacity 0.5s ease;
  font-weight: 700;
  text-align: left;

}

.fade-out {
  opacity: 0;
  transform: translate(-100px);
  transition: all linear 0.30s;
}

.fade-right {
  opacity: 0;
  transform: translate(100px);
  transition: all linear 0.30s;
}

.sub-heading {
  width: 80vw;
  margin: 0 auto;
  /* Center the subheading */
  text-align: center;
  /* Center the text within the subheading */
}

.sub-heading h2 {
  /* Your subheading styles here */
}

/* ///////////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////////// */

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.cont {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to left bottom, #F2E3C6 0%, #A7A1A5 100%);
  overflow: hidden;
}

.app {
  position: relative;
  min-width: 850px;
  height: 540px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.app__bgimg {
  position: absolute;
  top: 0;
  left: -2.5%;
  width: 105%;
  height: 100%;
  transition: transform 3.5s 770ms;
}

.app__bgimg-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.app__bgimg-image--1 {
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city22222.jpg') center center no-repeat;
  background-size: cover;
}

.app__bgimg-image--2 {
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/forest.jpg') center center no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0ms 1300ms;
  will-change: opacity;
}

.app__text {
  position: absolute;
  right: 165px;
  top: 150px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  z-index: 1;
}

.app__text-line {
  transition: transform 1500ms 400ms, opacity 750ms 500ms;
  will-change: transform, opacity;
  user-select: none;
}

.app__text-line--4 {
  font-size: 50px;
  font-weight: 700;
  color: #0A101D;
}

.app__text-line--3 {
  font-size: 40px;
  font-weight: 300;
}

.app__text-line--2 {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #0099CC;
}

.app__text-line--1 {
  margin-top: 15px;
}

.app__img {
  position: absolute;
  transform: translate3d(0, -750px, 0);
  width: 850px;
  height: 100%;
  transition: transform 3s cubic-bezier(0.6, 0.13, 0.31, 1.02);
  will-change: transform;
}

.initial {
  .app__img {
    transform: translate3d(0, 0, 0);
  }

  .app__text--1 .app__text-line {
    transform: translate3d(0, 0, 0);
    transition: transform 1500ms 1400ms, opacity 400ms 1600ms;
    opacity: 1;
  }
}

.active {
  .app__bgimg {
    transform: translate3d(10px, 0, 0) scale(1.05);
    transition: transform 5s 850ms ease-in-out;
  }

  .app__bgimg-image--2 {
    opacity: 1;
    transition: opacity 0ms 1500ms;
  }

  .app__img {
    transition: transform 3s cubic-bezier(0.6, 0.13, 0.31, 1.02);
    transform: translate3d(0, -1410px, 0);
  }

  .app__text--1 {
    z-index: -1;
    transition: z-index 0ms 1500ms;
  }

  .app__text--2 {
    z-index: 1;
  }
}

.mouse {
  position: relative;
  margin-right: 20px;
  min-width: 50px;
  height: 80px;
  border-radius: 30px;
  border: 5px solid rgba(255, 255, 255, 0.8);
}

.mouse:after {
  content: '';
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  animation: scroll 1s infinite alternate;
}

@keyframes scroll {
  100% {
    transform: translate(-50%, 15px);
  }
}

.pages {
  margin-left: 20px;
}

.pages__list {
  list-style-type: none;
}

.pages__item {
  position: relative;
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #fff;
  cursor: pointer;
}

.pages__item:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0, 0);
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  transition: 500ms;
}

.pages__item:hover:after {
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
}

.page__item-active:after {
  transform: translate(-50%, -50%) scale(1, 1);
  opacity: 1;
}

.icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 50px;
}

.icon-link img {
  width: 100%;
  vertical-align: top;
}

.icon-link--twitter {
  left: auto;
  right: 5px;
}


/* //////////////////////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////////////////////////// */

@media screen and (min-width:600px) and (max-width:850px) {
  .main-heading {
    /* font-size: 2.5px; */
    width: 100%;
    /* border: solid 2px red; */
  }

  .caption {
    /* border: solid 1px black; */
    width: 90%;
    /* width: 100%; */
    transform: translate(-230px, -200px);
  }
}

@media screen and (min-width:510px) and (max-width:600px) {
  .main-heading {
    font-size: 2.5rem;
    width: 100%;
    /* border: solid 2px red; */
  }

  .caption {
    /* border: solid 1px black; */
    width: 80%;
    /* width: 100%; */
    transform: translate(-200px, -200px);
  }
}

@media screen and (min-width:400px) and (max-width:510px) {
  .main-heading {
    font-size: 1.7rem;
    width: 100%;
    /* border: solid 2px red; */
  }

  .caption {
    /* border: solid 1px black; */
    width: 80%;
    /* width: 100%; */
    transform: translate(-150px, -200px);
  }
}

@media screen and (min-width:0px) and (max-width:400px) {
  .main-heading {
    font-size: 1.6rem;
    width: 100%;
    /* border: solid 2px red; */
  }

  .caption {
    /* border: solid 1px black; */
    width: 80%;
    /* width: 100%; */
    transform: translate(-120px, -200px);
  }
}