.why-card{
    height: 200px;
    width: 95%;
    /* border: solid 1px black; */
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    overflow: hidden;
}

.why-card-a{
    width: 35%;
    /* border: solid 1px black; */
    display: flex;
    justify-content: start;
    align-items: start;
}

.why-card-b{
    width: 65%;
    /* border: solid 1px black; */
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
}

.why-card-b h1 {
    font-size: 25px;
}

.why-card-b p {
    margin-top: 10px;
}

.why-card-box h1 {
    font-size: 40px;
    color: var(--combination-color);
}

.why-card-box{
    background-color:var(--dark-blue-color);
    width: 100px;
    height: 100px;
    border: solid 2px var(--combination-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.why-render{
    margin-top: 100px;
}