.trust-video-main {
    height: max-content;
    width: 100%;
    border: solid 2px var(--combination-color);
    padding: 10px;
    position: relative;
    margin-bottom: 40px;
}

.custom-video {
    width: 100%;
    height: auto;
    margin-top: 5px;
    /* position: relative; */
}

.play-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 60;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: var(--dark-blue-color);
    border: none;
  
}

.play-button-icon{
    color: var( --combination-color);
    font-size: 50px;
}

.contaiener-fluid-blue{
    background-color:var(--dark-blue-color);
}

.trust-heading{
    /* border: solid 1px var(--combination-color); */
    color: var(--combination-color);
}

.trust-typo{
    color: white;
}

.trust-sub-heading{
    color: white;
}

.trust-line{
    width: 40px;
    height: 2px;
    background-color: var(--combination-color);
}

.trust-sub-heading-main{
    width: 400px;
    /* border: solid 1px white; */
    display: flex;
    justify-content: start;
    align-items: center;
}