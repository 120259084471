.qoute-container-main {
    background-attachment: fixed;
    position: relative;
    height: 100vh;
    background-image: url('https://images.pexels.com/photos/4025501/pexels-photo-4025501.jpeg?auto=compress&cs=tinysrgb&w=600');
    background-size: cover;
    min-width: 100%;
    overflow: hidden;
    /* border: solid 1px red; */

}

.qoute-overlay {
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.7;
    position: absolute;
    left: 0%;
}

/* //// */

/* Glassmorphism card effect */
.card {}

.qoute-content-main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
    /* background-color: white;     */
    /* border: solid 1px white; */

    /* //// */
}

.qoute-send-main {
    height: 620px;
    width: 30%;
    background-color: white;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    /* background-color: white; */
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(5px);
    transform: translate(0px, 90px);
    /* border: solid 1px red; */
    /* border: 1px solid rgba(255, 255, 255, 0.3); */

}

.qoute-send-main input {
    /* border-radius: 10px; */
    border: none;
    border-bottom: solid 1px white;
    background-color: transparent;
    width: 100%;
    height: 25px;
    padding-left: 15px;
    margin-top: 5px;
    color: white;
    outline: none;
    /* outline: none; */
}

.qoute-send-main textarea {
    width: 100%;
    border-radius: 10px;
    padding-left: 15px;
    padding-top: 10px;
    background-color: transparent;
    color: white;
    border: solid 1px white;
    /* border: none; */
    outline: none;
    min-height: 200px;
    max-height: 200px;
}

.qoute-send-main button {
    height: 35px;
    width: 80%;
    background-color: transparent;
    color: white;
    transition: all ease-out 0.30s;
    border: solid 1px white;
    border-radius: 10px;
    margin-top: 20px;
    /* // */
    background-color: white;
    color: black;
}

.qoute-send-main label {
    color: white;
}

.qoute-send-main button:hover {
    background-color: white;
    color: black;
}

.qoute-img {
    height: 75%;
    width: 40%;
    /* border: solid 1px black; */
    background-image: url('../../../utiles//images/qoutemen.png');
    background-size: cover;
    z-index: 10;
    transform: translate(0px, 90px);
}

.qoute-entity {
    width: 80%;
    /* border: solid 1px black; */
    margin-top: 5px;
    margin-bottom: 15px;

}

.qoute-label {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    background: black;
    color: white;
}

.qoute-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 20;
    top: 80px;
    /* border: solid 1px white; */
}

.qoute-header h1 {
    color: white
}

.qoute-header p {
    text-align: center;
    max-width: 60%;
    color: white;
    font-size: 1rem;
    /* bottom: 20px; */
}

.qoute-container-main {
    height: 110vh;
}


@media screen and (min-width: 280px) and (max-width: 1300px) {

    .qoute-entity {
        /* height: 60px; */
        /* border: solid 1px black; */
    }

    .qoute-content-main {
        height: 90%;
        margin-top: 5%;
    }

    .qoute-send-main textarea {
        min-height: 100px;
        max-height: 100px;
    }

}

@media screen and (min-width: 600px) and (max-width: 800px) {
    .qoute-header p {
        font-size: 14px;
    }
}

@media screen and (min-width: 500px) and (max-width: 600px) {

    .qoute-header h1 {
        margin-top: -20px;
    }

    .qoute-header p {
        font-size: 14px;
        /* margin-top: -20px; */
    }
}

@media screen and (min-width: 280px) and (max-width: 500px) {

    .qoute-header h1 {
        margin-top: -20px;
    }

    .qoute-header p {
        font-size: 14px;
        /* margin-top: -20px; */
    }

    .qoute-content-main {
        margin-top: 50px;
    }

}

@media screen and (min-width: 550px) and (max-width: 992px) {
    .qoute-img {
        display: none;
        /* width: 60%; */
        /* opacity: 0; */
        /* border: solid 2px black; */
    }

    .qoute-send-main {
        width: 60%;
    }


    .qoute-send-main textarea {
        height: 200px;
    }


}

@media screen and (min-width: 0px) and (max-width: 550px) {
    .qoute-img {
        display: none;
        /* width: 60%; */
        /* opacity: 0; */
        /* border: solid 2px black; */
    }

    .qoute-send-main {
        width: 80%;
    }


    .qoute-send-main textarea {
        height: 200px;
    }

}

@media screen and (min-height: 0px) and (max-height: 550px) {
    .qoute-container-main {
        /* border: solid 1px red; */
        height: 190vh;
    }
}

@media screen and (min-height: 550px) and (max-height: 650px) {
    .qoute-container-main {
        /* border: solid 1px red; */
        height: 165vh;
    }
}


@media screen and (min-height: 650px) and (max-height: 750px) {
    .qoute-container-main {
        /* border: solid 1px red; */
        height: 140vh;
    }
}


@media screen and (min-height: 750px) and (max-height: 850px) {
    .qoute-container-main {
        /* border: solid 1px red; */
        height: 120vh;
    }
}

@media screen and (min-height: 850px) and (max-height: 992px) {
    .qoute-container-main {
        /* border: solid 1px red; */
        height: 110vh;
    }
}