
.container-gallery{
    /* border: solid 1px red; */
    width: 85%;
    margin: auto;
}

.Gallery_main{
  /* border: solid 1px orange; */
}

.gallery{
    column-count: 3;
    column-width: 33%;
    /* position: relative; */
    overflow: hidden;
   
}

@media (max-width:991px) {
    .gallery{
        column-count:2;
        column-width:50%;
    }
}

@media (max-width:550px) {
    .gallery{
        column-count:1;
        column-width:50%;
    }
}

.pics{
    margin-top: 16px;
    /* border: solid 1px black; */
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    transition: all ease-out 0.30s;
    /* margin-left: 10px; */
}

.pics:hover{
  border-radius: 25px;
}

.pics img{
  transition: all ease-out 0.30s;
}

.pics:hover.pics img{
  transform: scale(1.1);
}

.pics:hover .pics-overlay{
    opacity: 0.6;
    transition: all linear 0.30s;
}

.pics:hover .pics-plus{
    opacity: 1;
    transform: translate(-50%, -50%);
}

.tabs{
    width: 80%;
    margin: auto;
    margin-bottom: 50px;
    height: max-content;
    /* padding-top: 5px; */
    padding: 5px 5px 5px 5px;
}

.close-icon{
  margin-left: 100%;
  /* font-size: 10px; */
  /* color:  */

  /* border: solid 2px red; */
}

.load-more-container{
  height: max-content;
  padding: 10px;
  width: 100%;
  /* border: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-more-button{
  background-color: transparent;
  color: #28A745;
}

.load-more-button:hover{
  background-color: #28A745;
  border: solid 1px #28A745;
  color: white;

}

/* //// */

/* DynamicTabs.css */

/* Customize tab font size and color */
.custom-tabs .ant-tabs-tab {
    font-size: 16px; /* Adjust the font size */
    color: #333;    /* Adjust the font color */
  }
  
  /* Customize tab hover color */
  .custom-tabs .ant-tabs-tab:hover {
    color: var(--green); /* Adjust the hover color */
  }
  
  .custom-tabs .ant-tabs-tab-active, /* Target active tab */
  .custom-tabs .ant-tabs-tab-active:hover { /* Target active tab on hover */
    color: var(--green); /* Adjust the active tab color */
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--green) !important;
    /* color: var(--green); */
 }
  /* /////////////////// */

  .pics-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
    z-index:  50;
    /* border: solid 5px red; */
  }

  .pics-plus{
    color: white;
    position: absolute;
    z-index: 60;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -35%);
    font-size: 40px;
    opacity: 0;
    transition: all ease-out 0.30s;
  
  }

  .pics-label{
    font-size: 22px;
    color: white;
    opacity: 1;
    bottom: -50%;
    margin: auto;
    z-index: 60;
    position: absolute;
    transition: all ease-out 0.35s;
    text-align: center;
    width: 80%;
    line-height: 25px;
  }

  .pics:hover .pics-label{
    bottom: 40px;
  }

  .pics-cate{
    position: absolute;
    font-size: 18px;
    color: white;
    z-index: 60;
    bottom: -20%;
    margin: auto;
    /* bottom: -20%; */
    transition: all ease-out 0.45s;
    
    /* text-decoration: underline; */
  }

  .pics:hover .pics-cate{
    bottom: 10px;
    color:var(--green);
  }

  .modal-content{
    height: 90vh;
    width: 100%;
    background-position: center;
    background-size: contain; /* This will automatically adjust the image size */
    background-repeat: no-repeat;
    
  }

/* Custom styles to visually hide title and close button */
.custom-modal .ant-modal-header {
    visibility: hidden;
    height: 0;
    padding: 0;
  }
  
  .custom-modal .ant-modal-close {
    display: none;
  }


  @media screen and (min-width: 0px) and (max-width: 992px) {
    /* .Gallery_main{
      margin-bottom: 900px;
      border: solid 5px orange;
    } */
}