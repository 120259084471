.alllanding_container{
    min-width: 100%;
    height: 40vh;
    /* border: solid 4px black; */
    position: relative;
}

.alllanding_image{
    z-index: -20;
    /* background-position: c; */
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
}

.main_heading{
    position: absolute;
    /* border: solid 1px white; */
    height: 100%;
    width: max-content;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10%;
    color: white;
    flex-direction: column;
}

.main_heading h1{
    color: var(--combination-color);
}