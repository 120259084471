.plan-card{
    width: 90%;
    /* border: solid 1px black; */
    height: 500px;
    border-radius: 20px;
    border-bottom: solid 4px var(--combination-color);
    margin-bottom: 120px;
    background-color: white;
    /* box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75); */
}

.plan-card-heading{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* border: solid 1px black; */
    margin-top: 25px;
    width: 80%;
    margin-left: 10%;
    /* border: solid 2px black; */
    height: 50px;

}

.plan-card-heading p {
    padding: 4px;
    height: max-content;
    width: max-content;
    border: solid 1px var(--combination-color);
    border-radius: 10px;
    color: var(--combination-color);
    transition: all linear 0.30s;
    background-color: var(--dark-blue-color);
    /* margin-top: 49px; */
}


/* .plan-card-heading p:hover {
    background-color: blue;
    color: white;

} */

.plan-card-des{
    width: 80%;
    /* border: solid 1px black; */
    margin-left: 10%;
    color: rgb(62, 62, 62);
    margin-top: 25px;
}

.plan-line{
    width: 80%;
    margin-left: 10%;
}

.plan-card-contnet{
    width: 80%;
    height: max-content;
    /* border: solid 1px black; */
    margin-left: 10%;
}

.plan-card-contnet li{
    margin-bottom: 12px;
    color: rgb(126, 126, 126);
    list-style: none;
    display: flex;
    align-items: start;
    justify-content: start;
    width: 100%;
    /* border: solid 1px black; */
    margin-left: -30px;
}

.plan-card-contnet li a {
    margin-left: 2px;
}

.plan-tick{
    color: rgb(0, 201, 0);
    font-size: 40px;
    margin-top: -7px;
}

.plan-cencel{
    color: red;
    font-size: 18px;
    margin-top: 3px;
}

.plan-card-center{
    transform: scale(1.1);
}

.plan-chose{
    margin-bottom: 60px;
}