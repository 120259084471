.Team_Container{
    min-height: 100vh;
}

.slick_card_layout{
    /* width: ; */
    /* border: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 50px; */
    padding-top: 20px;
    padding-bottom: 20px;

 
  
}

.slick_card{
    width: 100%;
    border-radius: 10px;
    height: 350px;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    /* border: solid 1px black; */

}

.slick_bottom{
    width: 90%;
    background-color: var(--dark-blue-color);
    height: 120px;
    border-radius: 5px;
    position: relative;
}

.slick_content{
    height: 280px;
    width: 80%;
    /* border: solid 1px black; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    transition: all ease-out 0.30s;
    
}

.slick_content:hover{
    /* transform:; */
}

.slick-img{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: solid 4px var(--dark-blue-color);
    margin-bottom: 25px;
}

.slick_content h6 {
   color: rgb(105, 105, 105);
}

.slick_curve{
    height: 120px;
    width: 120px;
    background-color: var(--combination-color);
    position: absolute;
    right: -12%;
    bottom: -12%;
    border-radius: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: start;
    color: white;
    font-size: 40px;
    padding-left: 25px;
    padding-top: 30px;
}

.slick_curve::after{
    content: '';
    height: 110%;
    width: 110%;
    border-radius: 50%;
    /* opacity: 0.5; */
    z-index: -10;
    background-color: var(--dark-blue-color);
    left: -10%;
    position: absolute;
    top: -10%;

}

.FA-Max{
   transform: scale(1.2);
   transition: all linear 0.30s;
 }

 .FA-Min{
   transform: scale(1) translate(0px,10px) ;
   /* opacity: 0.8; */
   transition: all linear 0.30s;
  }

