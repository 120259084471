.testimonials_header {
    height: 65vh;
    width: 100%;
    position: relative;
    /* border: solid 1px black; */
}

.slider-item {
    width: 70%;
    height: 65vh;
    /* border: solid 2px yellow; */
}

.testimonial-main-heading {
    color: black;
    text-align: center;
    /* border: solid 1px black; */
    font-size: 20px;
    margin-top: -15px;
    font-weight: 600;
    font-style: italic;
    /* margin:  */
}

.testimonial-caption {
    width: 60%;
    margin-left: 20%;
    /* font-size: 20px; */
}

.testimonail-Story_All_Tags {
    color: black;
}

.testimonials-slider-item {
    /* border: solid 2px rebeccapurple; */
    width: 70%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.testimonials-slider-item img {
    height: 90px;
    width: 90px;
    border: solid 1px black;
    border-radius: 50%;
    margin-left: 47%;
    margin-top: 10vh;
}

.testimonial-active {
    /* width: 60%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.testimonail-name {
    margin: auto;
    width: 400px;
    /* border: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.testimonail-p {
    margin-left: 47%;
    margin-top: 25px;
}

.testimonial-curve {
    height: 200%;
    width: 80%;
    position: absolute;
    background-color: rgb(240, 240, 240);
    z-index: -20;
    transform: rotate(-50deg) translate(0px, -55%);

}

.custom-carousel-arrow {
    background-color: black;
}

/* //// */

/* Clientreview.css */
/* Target the Ant Design Carousel radio buttons and set their color to black */
.ant-carousel .slick-dots li.slick-active button {
    background-color: black;
}

/* Optionally, you can change the color of inactive radio buttons */
.ant-carousel .slick-dots li button {
    background-color: black;
    margin-bottom: 20px;
}

/* You can also style the radio button indicators differently, e.g., with a border */
.ant-carousel .slick-dots li button {
    background-color: transparent;
    border: 1px solid black;
    width: 10px;
    /* Adjust the size as needed */
    height: 10px;
    /* Adjust the size as needed */
    border-radius: 50%;
    /* Makes it round */
    margin-bottom: 20px;
}

/* Change the color of the active radio button indicator */
.ant-carousel .slick-dots li.slick-active button {
    background-color: black;
    margin-bottom: 20px;
}

/* ///////////////// */

@media screen and (min-width: 650px) and (max-width: 1000px) {
    .testimonail-name {
       /* border: solid 1px black; */
       transform: translate(15px);
    }
} 

@media screen and (min-width: 400px) and (max-width: 650px) {
    .testimonail-name {
       /* border: solid 1px black; */
       transform: translate(25px);
    }
} 

@media screen and (min-width: 300px) and (max-width: 400px) {
    .testimonail-name {
       /* border: solid 1px black; */
       transform: translate(-25px);
    }
} 

