.fancy {
    position: relative;
    border-radius: 30% 70% 70% 30% / 33% 30% 70% 67%;
    /* background-color: black; */
    height: 500px;
    width: 500px;
    background-size: cover;
}

.fancy::after {
    content: '';
    position: absolute;
    border-radius: 30% 70% 70% 30% / 33% 30% 70% 67%;
    background-color: var(--dark-blue-color);
    height: 500px;
    width: 500px;
    /* opacity: 0.5; */
    left: 25px;
    z-index: -10;
    border: solid 4px yellow;
}

.services_slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fancy_icon {
    border-radius: 30% 70% 70% 30% / 33% 30% 70% 67%;
    height: 70px;
    width: 70px;
    background-color: var(--dark-blue-color);
    color: var(--combination-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .fancy {
        height: 300px;
        width: 300px;
    }

    .fancy::after {
        height: 300px;
        width: 300px;
    }
}

@media screen and (min-width: 100px) and (max-width: 575px) {
    .fancy {
        height: 280px;
        width: 280px;
        margin: auto;
    }

    .fancy::after {
        height: 280px;
        width: 280px;
    }
    .services_content{
        width: 85%;
        margin-top: 20px;
    }
}